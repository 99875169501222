<template>
  <div style="height: 100%; overflow-y: auto">
    <!-- 过滤区域 -->
    <box-item>
      <div class="filter-container">
        <div class="filter-input-container">
          <!-- 区域 -->
          <div class="input-item">
            <div class="input-item-title">区域</div>
            <el-select v-model="v1">
              <el-option
                v-for="item of spaces"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>

          <!-- 计量表 -->
          <!-- <div class="input-item">
            <div class="input-item-title">计量表</div>
            <el-select v-model="v2">
              <el-option
                v-for="item of gauges"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </div> -->

          <!-- 比较类型 -->
          <div class="input-item">
            <div class="input-item-title">比较类型</div>
            <el-select v-model="v3">
              <el-option
                v-for="item of compareTypes"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>

          <!-- 时间刻度 -->
          <div class="input-item">
            <div class="input-item-title">时间刻度</div>
            <el-select v-model="v4">
              <el-option
                v-for="item of timeTypes"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </div>

          <!-- 报告期 -->

          <div class="input-item">
            <div class="input-item-title">报告期</div>
            <el-date-picker
              v-model="v5"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>

          <!-- <el-input></el-input> -->
        </div>

        <el-button type="primary" @click="loadData">提交</el-button>
      </div>
    </box-item>

    <!-- 数值 -->
    <!-- <data-single style="margin-top: 12px" :list="data1"></data-single> -->

    <!-- 进度 -->
    <progress-section style="margin-top: 12px" :list="data2"></progress-section>

    <!-- 折线图 -->
    <box-item style="margin-top: 12px" title="报告期二氧化碳排放">
      <line-chart :list="data3"></line-chart>
    </box-item>

    <!-- 表格 -->
    <box-item title="详细数据" style="margin-top:12px">
      <el-table :data=data3 height="300" width=100%>
        <el-table-column  prop="name"
        label="时间"
       ></el-table-column>

          <el-table-column  prop="value"
        label="碳排放(Kg)"
       ></el-table-column>
      </el-table>
    </box-item>

    <!-- 相关参数 -->
    <box-item style="margin-top: 12px" title="相关参数">
      <template #header>
        <el-select v-model="v6" @change="loadData4">
              <el-option
                v-for="item of gauges"
                :key="item.name"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
      </template>
      <line-chart :list="data4"></line-chart>
    </box-item>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { RandomNumber } from "@/utils";
import moment from "moment"
const v1 = ref(null);
const v2 = ref(null);
const v3 = ref(null);
const v4 = ref(null);
const v5 = ref(null);
const v6=ref(1)
const data1 = ref([
  {
    name: "报告期消耗 吨标准煤 (TCE)",
    value: 0,
  },
  {
    name: "报告期消耗 吨二氧化碳排放 (TCO2E)",
    value: 0,
  },
  {
    name: "标准煤单位面积值 (TCE/M²)",
    value: 0,
  },
  {
    name: "二氧化碳单位面积值 (TCO2E/M²)",
    value: 0,
  },
]);

const data2 = ref([
  {
    name: "分时用电排放二氧化碳",
    value: 0,
    df:true
  },
  {
    name: "二氧化碳排放占比",
    value: 0,
  },
  {
    name: "子空间总计占比",
    value: 0,
  },
]);

const data3 = ref([]);
const data4 = ref([]);

const loadData1 = () => {
  let data = data1.value;
  data[0].value = RandomNumber(10, 2);
  data[1].value = RandomNumber(1000, 2);
  data[2].value = RandomNumber(500, 2);
  data[3].value = RandomNumber(500, 2);
};

const loadData2 = () => {
  let data = data2.value;
  data[0].value = RandomNumber(100, 0);
  data[1].value = RandomNumber(100, 0);
  data[2].value = RandomNumber(100, 0);
};
const loadData3 = () => {
    let dateTime=new Date()*1
    let array=[]
    for(let index=0;index<100;index++){
        dateTime-=1000*3600*24
        array.push({
            name:moment(new Date(dateTime)).format("YYYY-MM-DD hh:mm:ss"),
            value:0.7+RandomNumber(0.3,2)
        })
    }
    data3.value=array
};

const loadData4 = () => {
    let dateTime=new Date()*1
    let array=[]
    for(let index=0;index<40;index++){
        dateTime-=1000*3600*24
        array.push({
            name:moment(new Date(dateTime)).format("YYYY-MM-DD hh:mm:ss"),
            value:400+RandomNumber(100,2)
        })
    }
    data4.value=array
};

const loadData = () => {
  loadData1();
  loadData2();
  loadData3();
  loadData4()
};

loadData();
</script>